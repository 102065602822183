@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: rgb(248, 246, 255);
}

.loader {
    position: relative;
    width: 55px;
    height: 30px;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-image: linear-gradient(#f43f5e 50px, transparent 0),
        linear-gradient(#f43f5e 50px, transparent 0),
        linear-gradient(#f43f5e 50px, transparent 0),
        linear-gradient(#f43f5e 50px, transparent 0),
        linear-gradient(#f43f5e 50px, transparent 0),
        linear-gradient(#f43f5e 50px, transparent 0);
    background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
    animation: rikSpikeRoll 0.85s linear infinite alternate;
}

@keyframes rikSpikeRoll {
    0% {
        background-size: 10px 3px;
    }

    16% {
        background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
    }

    33% {
        background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
    }

    50% {
        background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px
    }

    66% {
        background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px
    }

    83% {
        background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px
    }

    100% {
        background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px
    }
}


.custom-tooltip-2 {
    border: 1px solid #4f516a;
    border-radius: 0.375rem;
    background-color: #525668
}

.hover-link {
    text-decoration: underline;
}

.hover-link:hover {
    cursor: pointer;
}

.bg-lialec {
    background-color: rgb(248, 246, 255);
}

.min-w-dashboard {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

.selectable :hover {
    background-color: #f43f5e17;
}

.bg-purple {
    background-color: #4A154B;
}

.login-image {
    height: calc(100vh - 80px + 1rem);
    background: #f43f5e;
    width: 100%;
    padding: 12rem;
}

.hover-bg-purple:hover {
    background-color: #4A154B;
}

.slack-link {
    align-items: center;
    color: #fff;
    background-color: #4A154B;
    border: 0;
    border-radius: 48px;
    display: inline-flex;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    justify-content: center;
    text-decoration: none;
    width: 236px
}

.slack-image {
    height: 20px;
    width: 20px;
    margin-right: 12px
}

.search {
    color: #0f1115;
    font-weight: 500;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0.5em;
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    text-align: center;
    background: 0 0;
    color: #fff;
    border-bottom: 2px solid #fff;
    text-align: left;
    color: #0f1115;
    background: #fff;
    border-radius: 12px;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 25%);
    padding-left: 2.5em;
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: 0.75em center;
    width: 320px;
    border-radius: 100px;
    border: none;
}

.search-icon {
    position: absolute;
    top: 11.4px;
    left: 14px;
}

.search-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
    position: relative;
}

.sort-margin {
    margin-top: 0.75rem;
}

@media (max-width: 768px) {
    .full-height {
        padding-top: 3vh;
    }
}

@media (min-width: 768px) {
    .search-wrapper {
        margin-left: 0;
        margin-top: 1.5rem;
    }

    .sort-margin {
        margin-top: 24px;
    }

    .login-form {
        height: calc(100vh - 80px + 1rem);
    }
}

.display-none {
    display: none;
}

@media (min-width: 1200px) {
    .display-none-lg {
        display: none;
    }

    .display-flex-lg {
        display: flex;
    }

    .display-block-lg {
        display: block;
    }
}


@media (min-width: 900px) {
    .display-none-md {
        display: none;
    }

    .display-flex-md {
        display: flex;
    }

    .display-block-md {
        display: block;
    }
}

.kill-padding {
    padding: 0px !important;
}

@media (min-width: 400px) {
    .display-none-sm {
        display: none;
    }

    .display-flex-sm {
        display: flex;
    }

    .display-block-sm {
        display: block;
    }
}

.top-banner {
    text-align: center;
    color: white;
    top: 20px;
    font-weight: bold;
    margin-top: 0.5rem;
}

@media (min-width: 768px) {
    .top-banner {
        margin-top: 0;
    }
}

.sticky-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    border: 4px solid white;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #F43F5E;
    border-color: #F43F5E transparent #F43F5E transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bottom-dip {
    background-image: url(/images/BottomDip.png);
    height: 96px;
    background-position: center;
    background-size: cover;
}

@media (min-width: 768px) {
    .big-borders {
        border-radius: 10px;
    }
}

.bottom-left-quarter-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-bottom-left-radius: 110px;
    border: 2px solid white;
    border-right: 0px;
    border-top: 0px;
}

.bottom-left-quarter-circle-large {
    width: 20px;
    height: 20px;
    background-color: white;
    border-bottom-left-radius: 110px;
    border: 2px solid white;
    border-right: 0px;
    border-top: 0px;
}

.bottom-right-quarter-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-bottom-right-radius: 110px;
    border: 2px solid white;
    border-left: 0px;
    border-top: 0px;
}

.bottom-right-quarter-circle-large {
    width: 20px;
    height: 20px;
    background-color: white;
    border-bottom-right-radius: 110px;
    border: 2px solid white;
    border-left: 0px;
    border-top: 0px;
}

.top-right-quarter-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-top-right-radius: 110px;
    border: 2px solid white;
    border-bottom: 0px;
    border-left: 0px;
}

.top-right-quarter-circle-large {
    width: 20px;
    height: 20px;
    background-color: white;
    border-top-right-radius: 110px;
    border: 2px solid white;
    border-bottom: 0px;
    border-left: 0px;
}

.top-left-quarter-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-top-left-radius: 110px;
    border: 2px solid white;
    border-bottom: 0px;
    border-right: 0px;
}

.top-left-quarter-circle-large {
    width: 20px;
    height: 20px;
    background-color: white;
    border-top-left-radius: 110px;
    border: 2px solid white;
    border-bottom: 0px;
    border-right: 0px;
}

.top-title {
    position: absolute;
    left: calc(50% - 67.17px);
    top: 80px;
    font-size: 11px;
    border: 4px solid black;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
    border-radius: 20px;
    background-color: #e5e7eb;
}

.top-title-cocktail-page {
    font-size: 11px;
    border: 4px solid black;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
    border-radius: 20px;
    background-color: #e5e7eb;
}

.neon-text {
    color: #fff;
    text-shadow: 0 0 100px #000000, 0 0 300px #fff, 0 0 300px #fff, 0 0 50px #bc13fe, 0 0 40px #bc13fe, 0 0 100px #bc13fe, 0 0 200px #bc13fe, 0 0 100px #bc13fe;
}

.neon-box-pink {
    animation: pulsate 1.5s infinite alternate;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 0.8rem #bc13fe, 0 0 0.5rem #bc13fe, 0 0 0.3rem #bc13fe, inset 0 0 0.5rem #bc13fe;

}

.neon-box-orange {
    animation: pulsate 1.5s infinite alternate;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 0.8rem #ffb500, 0 0 0.5rem #ffb500, 0 0 0.3rem #ffb500, inset 0 0 0.5rem #ffb500;

}

.neon-box-green {
    animation: pulsate 1.5s infinite alternate;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 0.8rem #98ff6a, 0 0 0.5rem #98ff6a, 0 0 0.3rem #98ff6a, inset 0 0 0.5rem #98ff6a;

}

.neon-box-blue {
    animation: pulsate 1.5s infinite alternate;
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 0.8rem #6a9cff, 0 0 0.5rem #6a9cff, 0 0 0.3rem #6a9cff, inset 0 0 0.5rem #6a9cff;
}